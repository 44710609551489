<template>
	<div 
		v-if="campaignEdit && department"
		class="flex flex-col content-start h-full">
		
		<div class="flex-none bg-beige flex flex-col h-32">
            <div class="flex-grow flex px-4 pt-4">
                <div 
                    class="flex-none w-12 h-12 flex items-center justify-center"
                    @click="$emit('close')">
                    
                    <i class="fas fa-times text-black text-xl" />
                </div>
                <div class="flex-grow flex items-center justify-center truncate">
                    <h1 class="truncate">{{campaignEdit.name}}</h1>
                </div>
                <div class="flex-none w-12 h-12 flex items-center justify-center">
                    
                </div>
            </div>
            
            <div class="overflow-y-hidden overflow-x-auto flex">
                <Tabs 
                    class="flex-none justify-start px-8"
                    :tabs="tabs" 
                    :active="activeSection" 
                    @click="activeSection = $event.alias"
                />
            </div>
        </div>
		
		<!-- <pre
			class="overflow-auto w-200 h-full fixed top-0 left-0 bottom-0 bg-beige-dark"
			style="z-index: 1000"
			v-html="campaignEdit"
		/> -->
		
		<!-- <div 
			style="z-index: 1000"
			class="overflow-auto w-150 h-full fixed top-0 left-0 bottom-0 bg-beige-dark">
			
			dateRangeCrash: 
			<pre>{{ dateRangeCrash }}</pre> 
			
			<pre
				v-html="dateRangeEnumerate"
			/>
			
			hasDateRangeCrash
			<pre>{{ hasDateRangeCrash }}</pre>
		</div> -->
		
        <section 
            v-if="activeSection == 'details'"
            class="flex-grow py-2 px-8 overflow-auto">
            
				<form @submit.prevent>
					
					<InputSingleline 
						v-model="campaignEdit.name"
						required="true"
						label="Kampanjenavn"
						name="name"
						showRequiredWarning="true"
						ref="autofocus"
					/>
					
					<label>Avdeling</label>
					<select 
						:value="department.name"
						disabled>
						
						<option :value="department.name">{{department.name}}</option>
					</select>
					
					
					
					<label>Type</label>
					<select 
						v-model="campaignEdit.type"
						readonly
						disabled>
						
						<option value="products">Produkter</option>
						<option value="tasks">Oppgaver</option>
					</select>
					
					<label class="md:flex items-start ">
						<div class="flex-grow">
							Start og sluttdato
						</div>
						<div class="flex-none">
							{{campaignEdit.startAt | moment('lll')}} til {{campaignEdit.endAt | moment('lll')}}
						</div>
					</label>
					
					<!-- startAt: <pre>{{ campaignEdit.startAt }}</pre>
					endAt: <pre>{{ campaignEdit.endAt }}</pre> -->
					
					<div 
						v-if="hasDateRangeCrash"
						class="bg-red-600 text-white p-6 mb-3 rounded">
						
						<h3 class="font-bold">Advarsel!</h3>
						Datoer for kampanjen kan ikke overlappe en andre kampanjeperiode-datoer for samme avdeling. Finn datoer som ikke grået ut i kalenderen og prøv på nytt.
						<!-- Det er allerede en kampanje i angitte periode på respektive avdeling. Det er ikke mulig å gjennomføre flere kampanjer i samme periode. Endre dato i kalender til ledige perioder for denne kampanjen -->
					</div>
					
					<DatePicker 
						range 
						v-model="dateRange" 
						:clearable="false"
						:open="true"
						:inline="true"
						class="cursor-pointer" 
						:value-type="'timestamp'"
						:lang="{formatLocale: {firstDayOfWeek: 1}}"
						:disabled-date="checkIfDisabled"
					/>
					
					<label>Publisering</label>
					<select v-model="campaignEdit.published">
						<option :value="true">Publisert</option>
						<option :value="false">Ikke publisert</option>
					</select>
					
					<div class="md:grid grid-cols-2 gap-6">
						<div class="">
							<label>Topplister i Oversikt / Dashboards</label>
							<select v-model="campaignEdit.leaderboard.active">
								<option :value="false">Ikke vis topplister</option>
								<option :value="true">Vis topplister</option>
							</select>
						</div>
						<div>
							<label>Antall toppliste-plasseringer</label>
							<select 
								v-model.number="campaignEdit.leaderboard.positions"
								:readonly="!campaignEdit.leaderboard.active"
								:disabled="!campaignEdit.leaderboard.active">
								
								<option
									v-for="position in 10"
									:key="'position_'+position">
									
									{{position}}
								</option>
							</select>
						</div>
					</div>
					
					<InputMultiline 
						v-model="campaignEdit.description"
						:required="false"
						label="Beskrivelse av kampanjen"
						name="description"
					/>
					
					<!--<h1>Husk settings for leaderboard.</h1>-->
				</form>
			
		</section>
		
		<section 
            v-else-if="activeSection == 'products'"
            class="flex-grow overflow-auto">
			
            <div class="bg-beige-dark p-8">
				<div>Måloppnåelse i kampanjeperioden</div>
				
				<label 
					for="metricTotal"
					class="mt-2">
					
					<input 
						type="radio" 
						name="metricTotal"
						id="metricTotal"
						v-model="campaignEdit.campaignTargets.products.metric"
						value="total"
						class="mr-4 mt-1"
						@change="metricWasChanged('products', 'total')"
					/>
					
					Mål er avdelings totale salg
				</label>
				
				<div 
					v-if="campaignEdit.campaignTargets.products.metric == 'total'"
                    class="ml-8 p-4 bg-beige-light text-gray space-y-2 my-4">
					
					<div class="flex items-center">
						<div class="w-3/5 flex-grow">Totalsum</div>
						<input 
							class="text-right"
							type="number" 
							v-model.number="campaignEdit.campaignTargets.products.sum"
							style="margin-bottom: 0; width: 8rem;"
							placeholder="Kroner"
						/>
					</div>
					
				</div>
				
				<label 
					for="metricProduct"
					class="mt-2">
					
					<input 
						type="radio" 
						name="metricProduct"
						id="metricProduct"
						v-model="campaignEdit.campaignTargets.products.metric"
						value="individually"
						class="mr-4 mt-1"
						@change="metricWasChanged('products', 'individually')"
					/>
					
					Mål individuelt for avdelingsprodukter
				</label>
				
				
				
				
				
				<label>Produkter i kampanjen</label>
				
				<label 
					for="productsAll"
					class="mt-2">
					
					<input 
						type="radio" 
						id="productsAll"
						name="productsAll"
						v-model="campaignEdit.campaignTargets.products.mode"
						value="all"
						class="mr-4 mt-1"
						@change="modeWasChanged('products', 'all')"
					/>
					Bruk alle avdelingsprodukter
				</label>
				
				<label 
					for="productsSome"
					class="mt-2">
					
					<input 
						type="radio" 
						id="productsSome"
						name="productsSome"
						v-model="campaignEdit.campaignTargets.products.mode"
						value="some"
						class="mr-4 mt-1"
						@change="modeWasChanged('products', 'some')"
					/>
					Definer utvalgte produkter
				</label>
			</div>
			
			<div 
				v-if="department"
				class="p-8">
				
				<div v-if="department.products?.length">
					<div v-if="campaignEdit.campaignTargets.products.mode == 'all'">Alle produkter i kampanjen ({{ department.products.length }})</div>
					<div v-else-if="campaignEdit.campaignTargets.products.mode == 'some'">Utvalgte produkter i kampanjen ({{ campaignEdit.products.length }})</div>
				</div>
				
				<div class="space-y-4 mt-8">
					<div 
						v-for="(product,index) in department.products"
						:key="'productId_'+product.productId+'_'+index">
						
						<div class="flex p-4 bg-beige">
							<div 
								v-if="campaignEdit.campaignTargets.products.mode == 'some'"
								class="flex-grow cursor-default">
								
								<div @click="campaignEdit.products.find( p => p.productId == product.productId) ? campaignEdit.products = campaignEdit.products.filter( p => p.productId != product.productId) : campaignEdit.products.push( product )">
									<input 
										type="checkbox" 
										:checked="campaignEdit.products.find( p => p.productId == product.productId)"
										class="mr-2 pointer-events-none"
									/>
									
									{{product.name}} ({{product.value}} kr)
								</div>
									
							</div>
							<div 
								v-else
								class="flex-grow">
								
								{{product.name}} ({{product.value}} kr)
							</div>
						</div>
						
						<div v-if="campaignEdit.campaignTargets.products.metric == 'individually'">
							<div class="p-4 bg-beige-light text-gray space-y-2">
								<div class="flex items-center">
									<div class="w-3/5 flex-grow">Totalsum</div>
									<input 
										class="text-right"
										type="number" 
										@input="onSetTargetValue('product', product, 'sum', $event.target.value)"
										:value="onGetTargetValue('product', product, 'sum')"
										style="margin-bottom: 0; width: 8rem;"
										placeholder="Kroner"
										min="0"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
		</section>
		
		<section 
            v-else-if="activeSection == 'tasks'"
            class="flex-grow overflow-auto">
			
            <div class="bg-beige-dark p-8">
				<div>Måloppnåelse i kampanjeperioden</div>
				
				<label 
					for="metricTotal"
					class="mt-2">
					
					<input 
						type="radio" 
						name="metricTotal"
						id="metricTotal"
						v-model="campaignEdit.campaignTargets.tasks.metric"
						value="total"
						class="mr-4 mt-1"
						@change="metricWasChanged('tasks', 'total')"
					/>
					
					Mål er gjennomførte oppgaver
				</label>
				
				<div 
					v-if="campaignEdit.campaignTargets.tasks.metric == 'total'"
                    class="ml-8 p-4 bg-beige-light text-gray my-4">
					
					<div class="flex items-center">
						<div class="w-3/5 flex-grow">Totalt antall oppgaver å gjennomføre</div>
						<input 
							class="text-right"
							type="number" 
							v-model.number="campaignEdit.campaignTargets.tasks.qty"
							style="margin-bottom: 0; width: 8rem;"
							placeholder="Oppgaver"
						/>
					</div>
				</div>
				
				<label 
					for="metricProduct"
					class="mt-2">
					
					<input 
						type="radio" 
						name="metricProduct"
						id="metricProduct"
						v-model="campaignEdit.campaignTargets.tasks.metric"
						value="individually"
						class="mr-4 mt-1"
						@change="metricWasChanged('tasks', 'individually')"
					/>
					
					Mål individuelt for oppgaver gjennomført
				</label>
				
				
				
				<label>Oppgaver i kampanjen</label>
				
				<label 
					for="productsAll"
					class="mt-2">
					
					<input 
						type="radio" 
						id="productsAll"
						name="productsAll"
						v-model="campaignEdit.campaignTargets.tasks.mode"
						value="all"
						class="mr-4 mt-1"
						@change="modeWasChanged('tasks', 'all')"
					/>
					Bruk alle avdelingsoppgaver
				</label>
				
				<label 
					for="productsSome"
					class="mt-2">
					
					<input 
						type="radio" 
						id="productsSome"
						name="productsSome"
						v-model="campaignEdit.campaignTargets.tasks.mode"
						value="some"
						class="mr-4 mt-1"
						@change="modeWasChanged('tasks', 'some')"
					/>
					Definer utvalgte oppgaver
				</label>
				
			</div>
			
			<div 
				v-if="department"
				class="p-8">
				
				<div v-if="campaignEdit.campaignTargets.tasks.mode == 'all'">Alle oppgaver i kampanjen</div>
				<div v-else-if="campaignEdit.campaignTargets.tasks.mode == 'some'">Utvalgte oppgaver i kampanjen</div>
				
				<div class="space-y-4 mt-8">
					<div 
						v-for="(task,index) in department.tasks"
						:key="'taskId_'+task.taskId+'_'+index">
						
						<div class="flex p-4 bg-beige">
							<div 
								v-if="campaignEdit.campaignTargets.tasks.mode == 'some'"
								class="flex-grow cursor-default">
								
								<div @click="campaignEdit.tasks.find( t => t.taskId == task.taskId) ? campaignEdit.tasks = campaignEdit.tasks.filter( t => t.taskId != task.taskId) : campaignEdit.tasks.push( task )">
									<input 
										type="checkbox" 
										:checked="campaignEdit.tasks.find( t => t.taskId == task.taskId)"
										class="mr-2 pointer-events-none"
									/>
									
									{{task.name}}
								</div>
							</div>
							<div 
								v-else
								class="flex-grow">
								
								{{task.name}}
							</div>
						</div>
						
						<div v-if="campaignEdit.campaignTargets.tasks.metric == 'individually'">
							<div class="p-4 bg-beige-light text-gray">
								<div class="flex items-center mb-2">
									<div class="w-3/5 flex-grow">Antall gjennomførte oppgaver</div>
									<input 
										class="text-right"
										type="number" 
										@input="onSetTargetValue('task', task, 'qty', $event.target.value)"
										:value="onGetTargetValue('task', task, 'qty')"
										style="margin-bottom: 0; width: 8rem;"
										min="0"
									/>
								</div>
							</div>
						</div>
						
					</div>
				</div>
			</div>
		</section>
		
		<section 
            v-else-if="activeSection == 'status'"
            class="flex-grow overflow-auto">
			
			<CampaignEditStatus
				:campaignId="campaign.campaignId"
				:dateRangeEnumerate="dateRangeEnumerate"
			/>
		</section>
		
		<div
			v-if="activeSection != 'status'" 
			class="flex-none p-6 flex">
			
			<template v-if="activeSection == 'status'">
				<div class="flex-grow text-right">
					<button 
						@click.prevent="onExportCampaign()" 
						class="button submit"
						:class="{'loading' : loading == 'exporting'}">
						
						Export
					</button>
				</div>
			</template>
			<template v-else>
				<div class="flex-grow space-x-4 ">
					<a 
						class="button warning" 
						@click.prevent="onDeleteCampaign()" >
						
						Slett
					</a>
					<a 
						@click.prevent="onDuplicateCampaign()" 
						:disabled="loading == 'duplicating'"
						class="button transparent"
						:class="{'loading' : loading == 'duplicating'}">
						
						Duplisér
					</a>
				</div>
				<div class="flex-none space-x-4 text-right">
					<a 
						class="button" 
						@click="$emit('close')">
						
						Avbryt
					</a>
					
					<button 
						type="submit"
						@click.prevent="onUpdateCampaign()" 
						:disabled="loading == 'updating' || hasDateRangeCrash"
						class="button submit"
						:class="{'loading' : loading == 'updating'}">
						
						Lagre
					</button>
				</div>
			</template>
		</div>
		
	</div>
</template>


<script>
import DatePicker from 'vue2-datepicker';

export default {
	components: {
		DatePicker,
		'CampaignEditStatus': () => import('@/components/campaigns/CampaignEditStatus.vue'),
	},
	
	props: {
		campaign: {
			type: Object,
			required: true,
		}
	},
	
	computed:{
		departments(){
			return this.$store.getters.getDepartments;
		},
		
		department(){
			if ( !this.departments ) {
				return;
			}
			
			return this.departments.find( d => d.departmentId == this.campaign.departmentId);
		},
		
		campaigns(){
			return this.$store.getters.getCampaigns;
		},
		
		campaignTargetsProductsSum() {
			let sum = 0;
			
			this.campaignEdit.products.forEach( product => {
				if (product.target && product.target.sum) {
					sum += product.target.sum;
				}
			});
			
			return sum;
		},
		
		dateRangeEnumerate(){
			if ( 
				!this.campaignEdit || 
				!this.campaignEdit.startAt || 
				!this.campaignEdit.endAt){
				
				return null;
			}
			
			let dateRangeEnumerate = [];
			let startDate = this.$moment(this.campaignEdit.startAt);
			let endDate = this.$moment(this.campaignEdit.endAt);
			
			for (let m = this.$moment( startDate ); m.isSameOrBefore( endDate ); m.add(1, 'days')) {
				dateRangeEnumerate.push( m.format('YYYY-MM-DD') );
			}
			
			return dateRangeEnumerate;
		},
		
		hasDateRangeCrash(){
			if ( !this.dateRangeEnumerate ){
				return false;
			}
			
			if ( !this.campaignEdit.published ){
				return false;
			}
			
			return this.dateRangeEnumerate.find( date => this.checkIfDisabled( new Date(date) ) );
		},
	},
	
	data() {
		return {
			loading: null,
			campaignEdit: null,
			productIdToAdd: null,
			dateRange: null,
			
			tabs: [
				{
					name: 'Detaljer',
					alias: 'details',
				},
				{
					name: 'Produkter',
					alias: 'products',
				},
				{
					name: 'Oppgaver',
					alias: 'tasks',
				},
				{
					name: 'Status',
					alias: 'status',
				},
			],
			activeSection: 'details',
			dateRangeCrash: false,
		}
	},
	
	watch: {
		dateRange(newValue, oldValue) {
			this.campaignEdit.startAt = newValue[0];
			this.campaignEdit.endAt = this.$moment( newValue[1] ).endOf('day').valueOf();
		},
	},
	
	methods: {
		onExportCampaign(){
			alert('export coming');
		},
		
		onGetTargetValue(entity, item, target) {
			console.log('onGetTargetValue');
			console.log({entity, item, target});
			
			if ( entity == 'product') {
				const product = this.campaignEdit.products.find( p => p.productId == item.productId  );
				console.log('found product ', product);
				
				if ( 
					product &&
					product.target &&
					product.target[ target ] ){
					
					return product.target[ target ];
				}
			}
			else if ( entity == 'task') {
				const task = this.campaignEdit.tasks.find( t => t.taskId == item.taskId  );
				console.log('found task ', task);
				
				if ( 
					task &&
					task.target &&
					task.target[ target ] ){
					
					return task.target[ target ];
				}
			}
			
			return 0
		},
		
		onSetTargetValue( entity, item, target, value ){
			console.log('onSetTargetValue');
			console.log( {entity, item, target, value} );
			console.log( this.campaignEdit.products );
			
			if (entity == 'product') {
				let product = this.campaignEdit.products.find( p => p.productId == item.productId );
				
				if ( !product ){
					this.campaignEdit.products.push( item );
					product = this.campaignEdit.products.find( p => p.productId == item.productId );
				}
				
				let qty = product.target?.qty || 0;
				let sum = product.target?.sum || 0;
				
				if ( target == 'qty') {
					qty = value || 0;
				}
				else if ( target == 'sum') {
					sum = value || 0;
				}
				
				this.$set( product, 'target', {
					qty: Number( qty ),
					sum: Number( sum ),
				});
			}
			else if (entity == 'task') {
				let task = this.campaignEdit.tasks.find( t => t.taskId == item.taskId );
				
				if ( !task ){
					this.campaignEdit.tasks.push( item );
					task = this.campaignEdit.tasks.find( t => t.taskId == item.taskId );
				}
				
				let qty = task.target?.qty || 0;
				
				if ( target == 'qty') {
					qty = value || 0;
				}
				
				this.$set( task, 'target', {
					qty: Number( qty ),
				});
			}
		},
		
		checkIfDisabled(date) {
			// console.log('cal:', date );
			// console.log('cal:', date.getTime() );
			// console.log(this.campaigns);
			
			if ( !this.campaignEdit.published ){
				return false;
			}
			
			const campaignsWithPossibleCrash = this.campaigns
				.filter( c => c.published)
				.filter( c => c.campaignId != this.campaignEdit.campaignId)
				.filter( c => !c.deletedAt)
				// .filter( c => c.type == this.campaignEdit.type)
				.filter( c => c.departmentId == this.campaignEdit.departmentId);
			if ( 
				campaignsWithPossibleCrash && 
				campaignsWithPossibleCrash.length ) {
				
				// find the first hit with overlapping campaign date
				// console.log(hits[0].startAt);
				// console.log('cal:', date.getTime() );
				
				const hit = campaignsWithPossibleCrash.find( c => {
					if ( 
						date.getTime() >= c.startAt &&
						date.getTime() <= c.endAt ){
							
						return true;
					}
				});
				
				if ( hit ) {
					// this.dateRangeCrash = true;
					return true;
				}
			}
			
			return false;
		},
		
		async onDeleteCampaign(){
			try {
				if (! confirm('Er du sikker på at du vil slette kampanjen?')) return; 
				this.loading = 'deleting';
				const deleteCampaignResponse = await this.$store.dispatch('deleteCampaign', this.campaignEdit);
				this.$notify({ type: 'success', text: 'Kampanjen ble slettet' });
				this.$emit('close');
			} 
			catch (e) {
				this.$notify({ 
					type: 'error', 
					text: e.message || 'Kunne ikke slette kampanjen'
				});
		
				console.error(e);
			} 
			finally {
				this.loading = null;
			}
		},
		
		async onDuplicateCampaign(){
			try {
				this.loading = 'duplicating';
				
				const campaignDuplicate = {
					...this.campaignEdit,
					name: 'Kopi av '+this.campaignEdit.name,
					published: false,
				};
				
				const campaign = await this.$store.dispatch('createCampaign', campaignDuplicate);
				console.log('campaign response', campaign);
				
				this.$notify({ type: 'success', text: 'Kampanjen ble duplisert' });
				this.$emit('close');
			} 
			catch (e) {
				this.$notify({ 
					type: 'error', 
					text: e.message || 'Kunne ikke duplisere kampanjen'
				});
				
				console.error(e);
			} 
			finally {
				this.loading = null;
			}
		},
		
		async onUpdateCampaign(){
			try {
				if ( !this.campaignEdit.name ) {
					throw Error('En kampanje må ha et navn');
				}
				
				if ( !this.campaignEdit.startAt || !this.campaignEdit.endAt ) {
					throw Error('Kampanje må ha en definert start og sluttdato');
				}
				
				delete this.campaignEdit.status;
				
				this.loading = 'updating';
				const campaign = await this.$store.dispatch('updateCampaign', this.campaignEdit);
				this.$notify({ type: 'success', text: 'Endringen ble lagret' });
				// this.$emit('close');
			} 
			catch (e) {
				this.$notify({ 
					type: 'error', 
					text: e.message || 'Kunne ikke lagre endringen'
				});
		
				console.error(e);
			} 
			finally {
				this.loading = null;
			}
		},
		
		metricWasChanged( entity, metric ){
			console.log('entity', entity);
			console.log('metricWasChanged event', metric);
			
			if (entity == 'products') {
				if (metric == 'total') {
					console.log('total');
			        this.campaignEdit.products.forEach( product => {
			            delete product.target;
			        });
				}
				else if ( metric == 'individually') {
					console.log('individually');
					this.campaignEdit.campaignTargets.products.qty = 0;
					this.campaignEdit.campaignTargets.products.sum = 0;
				}
			}
			else if (entity == 'tasks') {
				if (metric == 'total') {
					console.log('total');
			        this.campaignEdit.tasks.forEach( task => {
			            delete task.target;
			        });
				}
				else if ( metric == 'individually') {
					console.log('individually');
					this.campaignEdit.campaignTargets.tasks.qty = 0;
				}
			}
		},
		
		modeWasChanged( entity, mode ){
			console.log('entity', entity);
			console.log('modeWasChanged event', mode);
			
			if (!this.department) {
				return;
			}
			// if ( !Array.isArray(this.campaignEdit.products) ) {
			// 	console.log('products was not a array, so converted it.');
			// 	this.campaignEdit.products = [];
			// }
			
			// if ( !Array.isArray(this.campaignEdit.tasks) ) {
			// 	console.log('tasks was not a array, so converted it.');
			// 	this.campaignEdit.tasks = [];
			// }
			
			// if (entity == 'products') {
			// 	// clear all!
			// 	// this.campaignEdit.products = [];
				
			// 	if (mode == 'all') {
			// 		console.log('ALL!');
			// 	}
			// 	else if (mode == 'some') {
			// 		console.log('SOME!');
			// 	}
			// }
			// else if (entity == 'tasks') {
			// 	// clear all!
			// 	// this.campaignEdit.tasks = [];
				
			// 	if (mode == 'all') {
			// 		console.log('ALL!');
			// 	}
			// 	else if (mode == 'some') {
			// 		console.log('SOME!');
			// 	}
			// }
		},
		
		createCopy(){
			this.campaignEdit = JSON.parse(
				JSON.stringify({
					
					// metric: 'total',
					// productsMode: 'all',
					// goal: {
					//     active: false,
					//     qty: null,
					//     sum: null,
					// },
					published: false,
					type: 'products',
					products: [],
					tasks: [],
					campaignTargets: {
						products: {
							metric: 'total', // or 'individually'
							mode: 'all',
							qty: 0,
							sum: 0,
						},
						tasks: {
							metric: 'total', // or 'individually'
							mode: 'all',
							qty: 0,
						},
					},
					leaderboard: {
						active: false,
						positions: 5,
					},
					departmentId: null,
					startAt: null,
					endAt: null,
					...this.campaign,
				})
			);
		},
	},
	
	async mounted(){
		this.dateRange = [
			this.campaign.startAt,
			this.campaign.endAt,
		];
		
		this.createCopy();
		
		if ( this.campaignEdit.type == 'products' ) {
			this.tabs = this.tabs.filter( tab => tab.alias != 'tasks');
		}
		else {
			this.tabs = this.tabs.filter( tab => tab.alias != 'products');
		}
	},
}
</script>